import { Ionicons, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import type { TFunction } from "i18next";
import _ from "lodash";
// eslint-disable-next-line import/no-named-default
import { default as MomentLib } from "moment";
import { extendMoment } from "moment-range";
import {
  Actionsheet,
  Badge,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Modal,
  TextArea,
  useDisclose,
  useTheme,
} from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, ScrollView, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import CommonHeaderDivider from "../commons/CommonHeaderDivider";
import CustomBrandingMobileScreenHeader from "../components/CustomBrandingMobileScreenHeader";
import FloatingButton from "../components/FloatingButton";
import MacroTargetInfo from "../components/MacroTargetInfo";
import MealSlotInPlanner from "../components/MealSlotInPlanner";
import QuickAddModal from "../components/QuickAddModal";
import CalendarStripComponent from "../components/SlidingCalendar";
import { commonStyles, DEFAULT_LANGUAGE, isDesktopScreen, Routes, Scale } from "../constants";
import {
  addMealToPlanner,
  addSingleFoodMealToPlanner,
  createCalendarDays,
  findLastDateThereAreMealsPlannedWithThisNutritionDayPlan,
  generateMealsPrefetchOptions,
  getActualValueForMacro,
  getDayOfWeekAsString,
  getPlannerData,
  getTargetValueForMacro,
} from "../helpers/diaryHelpers";
import {
  formatMealType,
  formatMomentAsBackendFormatDateString,
  formatUserForDisplay,
  getLocalizedDay,
  getNumberOfDaysUntilNextMonday,
  isDateInCurrentWeek,
} from "../helpers/generalHelpers";
import {
  areWeAnOpinionatedRecipeInspirationApp,
  doesOrganisationHaveManagedPlanning,
  FeatureFlag,
  getOrganisation,
  isFeatureFlagEnabled,
  isUserDiy,
  isUserInPaymentRequiredOrganisation,
} from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type {
  CalendarDay,
  CalendarItem,
  Food,
  FoodGenerateMealsListApiArg,
  MealMomentEnum,
  MealSlotSpecification,
  Organisation,
  SuggestedServing,
} from "../services/backendTypes";
import logger from "../services/logger";
import { calendarDaysSelector, currentDayInPlannerSelector, plannerSlice } from "../slices/plannerSlice";
import {
  localeSelector,
  userSlice,
  viewAsUserSelector,
  viewAsUserWeeklyNutritionPlanSelector,
  weeklyNutritionPlanSelector,
} from "../slices/userSlice";
import { CopyMealsModal } from "./CopyMealsModal";
import styles from "./DiaryViewScreenStyles";

const {
  useFoodQuickAddMealCreateMutation,
  usePlannerCalendarItemCreateMutation,
  // NOTE: There is a scoping problem with usePrefetch, I don't know why
  // eslint-disable-next-line @typescript-eslint/unbound-method
  usePrefetch,
  usePlannerCalendarDayPartialUpdateMutation,
  usePlannerPlanSingleFoodMealCreateMutation,
  usePlannerAutoPlanDayCreateMutation,
  usePlannerCopyMealsFromDateCreateMutation,
  useCheckoutSubscriptionVerificationCreateMutation,
} = backendApi;

// NOTE: The library has a typescript error in it which we cannot fix
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const moment = extendMoment(MomentLib);

const noteSchema = Yup.object().shape({
  // NOTE: A workaround for this issue: https://github.com/jquense/yup/issues/1367
  note: Yup.string().trim().required().nullable(false) as Yup.StringSchema<string>,
});
type NoteFormSchema = Yup.InferType<typeof noteSchema>;

const areTwoCalendarItemsEqual = (a: CalendarItem, b: CalendarItem): boolean => {
  if ("ingredient" in a.meal && "ingredient" in b.meal) {
    // SingleFoodMeal
    if (a.meal.ingredient.modified !== b.meal.ingredient.modified) {
      return false;
    }
  }

  return Boolean(a.id === b.id && a.modified && b.modified && a.modified === b.modified);
};

const areTwoCalendarItemsArraysEqual = (a: CalendarItem[], b: CalendarItem[]): boolean =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  a.every((ci, i) => b && b[i] && areTwoCalendarItemsEqual(ci, b[i]));

const MemoizedMealSlotInPlanner = React.memo(MealSlotInPlanner, (prevProps, nextProps) =>
  Boolean(
    prevProps.mealSlotSpecification.id === nextProps.mealSlotSpecification.id &&
      prevProps.calendarItems?.length === nextProps.calendarItems?.length &&
      prevProps.currentCalendarDay === nextProps.currentCalendarDay &&
      prevProps.calendarItems &&
      nextProps.calendarItems &&
      areTwoCalendarItemsArraysEqual(prevProps.calendarItems, nextProps.calendarItems)
  )
);

const NutritionOverviewForTheDay = ({
  actualKcalsForTheDay,
  targetKcalsForTheDay,
  actualProteinForTheDay,
  targetProteinForTheDay,
  actualFatForTheDay,
  targetFatForTheDay,
  actualCarbsForTheDay,
  targetCarbsForTheDay,
  t,
  shouldShowTargetMacros,
}: {
  actualKcalsForTheDay: number;
  targetKcalsForTheDay: number;
  actualProteinForTheDay: number;
  targetProteinForTheDay: number;
  actualFatForTheDay: number;
  targetFatForTheDay: number;
  actualCarbsForTheDay: number;
  targetCarbsForTheDay: number;
  t: TFunction;
  shouldShowTargetMacros: boolean;
}): JSX.Element => (
  <View testID={"diaryViewScreen-nutrition-overview"}>
    <View style={styles.macroOverviewTargetContainer}>
      <MacroTargetInfo
        title={t("general.kcal_short")}
        actualValue={actualKcalsForTheDay}
        targetValue={targetKcalsForTheDay}
        showTarget={shouldShowTargetMacros}
      />
      <MacroTargetInfo
        title={t("general.protein_short")}
        actualValue={actualProteinForTheDay}
        targetValue={targetProteinForTheDay}
        style={styles.proteinForTheDayText}
        showTarget={shouldShowTargetMacros}
      />
    </View>

    <View style={styles.macroOverviewTargetContainer}>
      <MacroTargetInfo
        title={t("general.fat")}
        actualValue={actualFatForTheDay}
        targetValue={targetFatForTheDay}
        showProgressBar={false}
        showTarget={shouldShowTargetMacros}
      />
      <MacroTargetInfo
        title={t("general.carbohydrates_short")}
        actualValue={actualCarbsForTheDay}
        targetValue={targetCarbsForTheDay}
        showProgressBar={false}
        showTarget={shouldShowTargetMacros}
      />
    </View>
  </View>
);

const MemoizedNutritionOverviewForTheDay = React.memo(NutritionOverviewForTheDay);

type Props = NativeStackScreenProps<RootStackParamList, Routes.DiaryViewScreen>;

const DiaryViewScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useTranslation();

  const getOrganisationForBrandingPreview = route?.params?.getOrganisationForBrandingPreview;

  const theme = useTheme();

  const dispatch = useDispatch();
  const isDesktop = isDesktopScreen();

  const [createCalendarItem] = usePlannerCalendarItemCreateMutation();
  const [createQuickAddMealBackendCall] = useFoodQuickAddMealCreateMutation();
  const [partialUpdateCalendarDayBackendCall, { isLoading: isLoadingPartialUpdateCalendarDay }] =
    usePlannerCalendarDayPartialUpdateMutation();
  const [planSingleFoodMealBackendCall] = usePlannerPlanSingleFoodMealCreateMutation();
  const [autoPlanDay, { isLoading: isLoadingAutoPlanDay }] = usePlannerAutoPlanDayCreateMutation();
  const [copyFromDate, { isLoading: isLoadingCopyFromDate }] = usePlannerCopyMealsFromDateCreateMutation();
  const [checkSubscription] = useCheckoutSubscriptionVerificationCreateMutation();

  const [mealSlotForActionSheetItems, setMealSlotForActionSheetItems] = useState<MealMomentEnum>("BREAKFAST");

  const { isOpen: isOpenQuickAdd, onOpen: onOpenQuickAdd, onClose: onCloseQuickAdd } = useDisclose();
  const { isOpen: isOpenNoteDialog, onOpen: onOpenNoteDialog, onClose: onCloseNoteDialog } = useDisclose();
  const { isOpen: isOpenCopyMealsTo, onOpen: onOpenCopyMealsTo, onClose: onCloseCopyMealsTo } = useDisclose();
  const {
    isOpen: isOpenMealSlotAddMore,
    onOpen: onOpenMealSlotAddMore,
    onClose: onCloseMealSlotAddMore,
  } = useDisclose();
  const { isOpen: isOpenMealSlotMenu, onOpen: onOpenMealSlotMenu, onClose: onCloseMealSlotMenu } = useDisclose();

  const viewAsUser = useSelector(viewAsUserSelector);
  const weeklyNutritionPlan = useSelector(
    viewAsUser ? viewAsUserWeeklyNutritionPlanSelector : weeklyNutritionPlanSelector
  );

  const currentDayInPlanner = useSelector(currentDayInPlannerSelector);

  const calendarDaysInStore = useSelector(calendarDaysSelector);

  const userLocaleFromStore = useSelector(localeSelector);

  // TODO: Refactor the below to make it more readable
  const {
    refetchCalendarDays,
    calendarDayListResponse,
    plannerArgs,
    createNewCalendarDay,
    user,
    isLoadingCreateNewCalendarDay,
    isLoadingCalendarDayList,
  } = getPlannerData();

  // Put the returned data for the CalendarDays in the store
  _.forEach(calendarDayListResponse?.results, (calendarDay) => {
    dispatch(plannerSlice.actions.storeCalendarDay(calendarDay));
  });

  // TODO: Prefetch USER_GENERATED meals search
  const foodGenerateMealsListPrefetch = usePrefetch("foodGenerateMealsList");

  useEffect(() => {
    // NOTE: Do not to call this on every time appLastOpenedDateString is updated - this will trigger an infinite loop
    dispatch(userSlice.actions.storeAppLastOpened(moment().toISOString()));
  }, [dispatch]);

  const currentCalendarDay = calendarDaysInStore[formatMomentAsBackendFormatDateString(currentDayInPlanner)];

  const day = getDayOfWeekAsString(currentDayInPlanner);
  if (weeklyNutritionPlan && !(day in weeklyNutritionPlan)) {
    throw new Error(`"${day}" not found in weekly nutrition plan`);
  }
  const nutritionPlanForCurrentDay = _.get(weeklyNutritionPlan, day);

  // NOTE: We need to find all MealSlotSpecification (MSS) objects with the same meal_moment as
  // there can be multiple (same) MSS due to when the NutritionDayPlan (NDP)
  // is updated it will result in entirely new MSS objects. Doing it this way prevents CalendarItems from being lost
  const getCalendarItemsForSlot = (
    mealSlotSpecification: MealSlotSpecification,
    calendarDay: CalendarDay
  ): CalendarItem[] => _.filter(calendarDay.calendar_items, { meal_moment: mealSlotSpecification.meal_moment });

  let nutritionOverviewForTheDay = null;

  let orderedMealSlotsForTheDay: MealSlotSpecification[] = [];
  if (currentCalendarDay && nutritionPlanForCurrentDay) {
    if (!user) {
      logger.warn("No user found");
    } else {
      const createGenerateMealsArgs = (mss: MealSlotSpecification): FoodGenerateMealsListApiArg => ({
        mealSlotSpecification: mss.id,
        ingredientSearch: "",
        favourite: false,
        sourceProvider: ["WEEKMEALS"],
        tags: [],
        page: 1,
        user: user.id,
        mealTypes: [mss.meal_type],
        kcal: mss.kcal || 0, // NOTE: this included only for cache busting
        protein: mss.protein || 0, // NOTE: this is included only for cache busting
      });

      const createGenerateMealsPrefetchPromise = (mss: MealSlotSpecification): Promise<void> =>
        Promise.resolve(foodGenerateMealsListPrefetch(createGenerateMealsArgs(mss), generateMealsPrefetchOptions));

      const prefetchPromises = nutritionPlanForCurrentDay.meal_slot_specifications.map(
        createGenerateMealsPrefetchPromise
      );

      Promise.all(prefetchPromises).catch((e) => {
        logger.warn("Error prefetching foodGenerateMealsList", e);
      });
    }

    // TODO: Put this in a helper
    const MEAL_MOMENT_TO_ORDERING: { [MME in MealMomentEnum]: number } = {
      BREAKFAST: 0,
      MORNING_SNACK: 1,
      LUNCH: 2,
      AFTERNOON_SNACK: 3,
      DINNER: 4,
      SNACK: 5,
      LATE_SNACK: 6,
    };

    const orderMealSlotByMealMoment = (mealSlotSpecification: MealSlotSpecification): number =>
      mealSlotSpecification.meal_moment ? MEAL_MOMENT_TO_ORDERING[mealSlotSpecification.meal_moment] : 100;

    orderedMealSlotsForTheDay = _.orderBy(
      nutritionPlanForCurrentDay.meal_slot_specifications,
      orderMealSlotByMealMoment
    );

    const getCalendarItemsForSlotCurried = (mealSlotSpecification: MealSlotSpecification): CalendarItem[] =>
      getCalendarItemsForSlot(mealSlotSpecification, currentCalendarDay);

    const calendarItemsForToday = _.map(
      nutritionPlanForCurrentDay.meal_slot_specifications,
      getCalendarItemsForSlotCurried
    ).flat();

    const actualKcalsForTheDay = getActualValueForMacro(calendarItemsForToday, "kcal");
    const actualProteinForTheDay = getActualValueForMacro(calendarItemsForToday, "protein");

    const targetKcalsForTheDay = getTargetValueForMacro(nutritionPlanForCurrentDay, "kcal");
    const targetProteinForTheDay = getTargetValueForMacro(nutritionPlanForCurrentDay, "protein");

    const actualFatForTheDay = getActualValueForMacro(calendarItemsForToday, "fat");
    const targetFatForTheDay = getTargetValueForMacro(nutritionPlanForCurrentDay, "fat");

    const actualCarbsForTheDay = getActualValueForMacro(calendarItemsForToday, "carbohydrates");
    const targetCarbsForTheDay = getTargetValueForMacro(nutritionPlanForCurrentDay, "carbohydrates");

    let shouldShowTargetMacros = true;
    if (user) {
      const organisation = getOrganisation(user || viewAsUser);
      if (organisation) {
        shouldShowTargetMacros = !areWeAnOpinionatedRecipeInspirationApp(organisation);
      }
    }

    nutritionOverviewForTheDay = (
      <MemoizedNutritionOverviewForTheDay
        {...{
          actualKcalsForTheDay,
          actualProteinForTheDay,
          actualFatForTheDay,
          actualCarbsForTheDay,
          targetKcalsForTheDay,
          targetProteinForTheDay,
          targetFatForTheDay,
          targetCarbsForTheDay,
          t,
          shouldShowTargetMacros,
        }}
      />
    );
  }

  /** The if statement if(user || viewAsUser) does not prevent a type error
   * when calling getOrganisation. Only by assigning the variable does the type checker realise
   * that the result of If(user || viewAsUser) cannot be null. * */
  let organisation: Organisation | undefined;
  const orgUser = user || viewAsUser;
  if (orgUser) {
    organisation = getOrganisation(orgUser);
  }

  const findMealSlotSpecification = (mealMoment: MealMomentEnum): MealSlotSpecification | undefined =>
    nutritionPlanForCurrentDay
      ? _.find(nutritionPlanForCurrentDay.meal_slot_specifications, {
          meal_moment: mealMoment,
        })
      : undefined;

  const onPressPlanNewMeal = (mealMoment: MealMomentEnum): void => {
    const mealSlotSpecification = findMealSlotSpecification(mealMoment);
    if (!mealSlotSpecification) {
      throw new Error("Could not find meal slot specification");
    }

    navigation.push(Routes.AddRecipeTemplateStack, {
      // NOTE: We are probably defining the types wrong
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      screen: Routes.RecipeSearchScreen,
      params: { mealSlotSpecification, currentCalendarDay },
    });
  };

  const createMealSlotInPlanner = (mealSlot: MealSlotSpecification): JSX.Element | null => {
    // NOTE: The same number of hooks must be created every time so the return statement must go after
    if (!currentCalendarDay) {
      return null;
    }

    const calendarItemsForMealSlot = getCalendarItemsForSlot(mealSlot, currentCalendarDay);

    return (
      <MemoizedMealSlotInPlanner
        key={mealSlot.meal_moment}
        title={formatMealType(mealSlot.meal_type, t)}
        actualValue={getActualValueForMacro(calendarItemsForMealSlot, "kcal")}
        targetValue={mealSlot.kcal ?? 0}
        calendarItems={calendarItemsForMealSlot}
        currentCalendarDay={currentCalendarDay}
        navigation={navigation}
        mealSlotSpecification={mealSlot}
        // TODO: Is this superfluous or are we going to do something with it?
        onPressPlannedStatus={(item) => logger.debug("onPress MealCategoryCard with item:", item)}
        refetchCalendarDays={refetchCalendarDays}
        onPressAddMoreComponent={() => {
          if (!mealSlot.meal_moment) throw new Error("meal_moment is not defined");

          setMealSlotForActionSheetItems(mealSlot.meal_moment);
          onOpenMealSlotAddMore();
        }}
        onPressPlanNewMeal={() => {
          if (!mealSlot.meal_moment) throw new Error("meal_moment is not defined");
          onPressPlanNewMeal(mealSlot.meal_moment);
        }}
        onPressMenu={() => {
          if (!mealSlot.meal_moment) throw new Error("Meal slot has no meal moment");

          setMealSlotForActionSheetItems(mealSlot.meal_moment);
          onOpenMealSlotMenu();
        }}
      />
    );
  };

  const diaryComponent = _.map(orderedMealSlotsForTheDay, createMealSlotInPlanner);

  const noWeeklyNutritionPlanMessage = (
    // TODO: center not working
    <Center>
      <View style={{ marginTop: Scale(12) }}>
        <Text style={{ fontWeight: "bold" }}>{t("planner.no_weekly_nutrition_plan_message")}</Text>
      </View>
    </Center>
  );

  // FIXME: This should not be hardcoded but chosen based on logic for what time of day it is
  const getMealSlotForQuickButtons = (): MealSlotSpecification | undefined =>
    nutritionPlanForCurrentDay ? nutritionPlanForCurrentDay.meal_slot_specifications[0] : undefined;

  const addSingleFoodMealToPlannerWrapper = (
    food: Food,
    suggestedServing: SuggestedServing,
    quantity: number,
    mealSlot: MealSlotSpecification | undefined = getMealSlotForQuickButtons()
  ): Promise<void> => {
    if (!currentCalendarDay) {
      return Promise.reject(new Error("No current calendar day"));
    }
    if (!mealSlot) {
      return Promise.reject(new Error("No meal slot"));
    }

    return addSingleFoodMealToPlanner({
      food,
      suggestedServing,
      quantity,
      currentCalendarDay,
      mealSlot,
      refetchCalendarDays,
      dispatch,
      planSingleFoodMealBackendCall,
    });
  };

  const floatingButton = !isFeatureFlagEnabled(organisation || null, FeatureFlag.DisableProductLogging) ? (
    <FloatingButton
      navigation={navigation}
      addSingleFoodMealToPlanner={addSingleFoodMealToPlannerWrapper}
      displayQuickAddModal={onOpenQuickAdd}
      mealSlotSpecifications={nutritionPlanForCurrentDay?.meal_slot_specifications ?? undefined}
    />
  ) : null;
  const resetViewAsUser = (): void => {
    dispatch(plannerSlice.actions.resetCalendarDays());
    dispatch(userSlice.actions.setViewAsUser(null));
    navigation.goBack();
  };

  const viewAsUserBackButtonIcon = {
    as: MaterialIcons,
    name: "arrow-back",
    color: "white",
  };

  const viewAsUserHeaderComponent = viewAsUser ? (
    <Flex flexDirection={"row"} alignItems={"center"} bgColor={"#9C9BDF"}>
      <Flex flexDirection={"row"} justifyContent="flex-start" alignItems={"center"}>
        <IconButton
          size={"md"}
          _icon={viewAsUserBackButtonIcon}
          testID={"diaryViewScreen-viewAsUser-back-button"}
          onPress={resetViewAsUser}
        />

        {/* TODO: Ideally this banner would appear above all screens
        while performing a view as user, but the diary screen is fine for now */}
        <Flex ml={Scale(16)}>
          <Center>
            <Text testID={"diaryViewScreen-viewAsUser-banner"} style={{ color: "white", fontWeight: "bold" }}>
              {t("planner.view_as_user_banner_title", {
                username: viewAsUser ? formatUserForDisplay(viewAsUser) : "THIS_SHOULD_NEVER_HAPPEN",
              })}
            </Text>
          </Center>
        </Flex>
      </Flex>
    </Flex>
  ) : null;

  const createQuickAddMeal = async ({
    mealSlotSpecificationId,
    calories,
    protein,
    fat,
    carbohydrates,
  }: {
    mealSlotSpecificationId: number;
    calories: number;
    protein: number;
    fat: number;
    carbohydrates: number;
  }): Promise<void> => {
    if (!currentCalendarDay) {
      throw new Error("currentCalendarDay is not set");
    }

    onCloseQuickAdd();

    const quickAddMeal = await createQuickAddMealBackendCall({
      quickAddMealRequest: {
        kcal: Number(calories),
        protein: Number(protein),
        fat: Number(fat),
        carbohydrates: Number(carbohydrates),
      },
    }).unwrap();

    if (!nutritionPlanForCurrentDay) {
      throw new Error("nutritionPlanForCurrentDay is not set");
    }
    const mealSlot = _.find(nutritionPlanForCurrentDay.meal_slot_specifications, {
      id: Number(mealSlotSpecificationId),
    });

    if (!mealSlot) {
      throw new Error("Could not find meal slot");
    }

    await addMealToPlanner({
      currentCalendarDay,
      mealSlot,
      mealContentType: "quickaddmeal",
      meal: { ...quickAddMeal, content_type: "QuickAddMeal", id: quickAddMeal.id },
      createCalendarItem,
      refetchCalendarDays,
    });
  };

  const quickAddModal = nutritionPlanForCurrentDay ? (
    <Modal isOpen={isOpenQuickAdd} onClose={onCloseQuickAdd} size="xl">
      <Modal.Content bgColor={"white"}>
        <Modal.CloseButton />
        <Modal.Header fontSize="4xl" fontWeight="bold" bgColor={"white"}>
          {t("planner.quick_options.quick_add_title")}
        </Modal.Header>
        <Modal.Body bgColor={"white"}>
          <QuickAddModal
            mealSlotSpecifications={nutritionPlanForCurrentDay.meal_slot_specifications}
            createQuickAddMeal={createQuickAddMeal}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  ) : null;

  const copyMealsToModalComponent = (
    <Modal isOpen={isOpenCopyMealsTo} onClose={onCloseCopyMealsTo} size="xl">
      <Modal.Content bgColor={"white"}>
        <Modal.CloseButton testID="copyMealMoment-modal-close-button" />
        <Modal.Header fontSize="4xl" fontWeight="bold" bgColor={"white"}>
          {t("planner.copy_meals_to_modal.title", {
            mealMoment: formatMealType(mealSlotForActionSheetItems, t).toLowerCase(),
          })}
        </Modal.Header>
        <Modal.Body bgColor={"white"}>
          <CopyMealsModal
            user={user}
            onClose={onCloseCopyMealsTo}
            currentCalendarDay={currentCalendarDay}
            mealMoment={mealSlotForActionSheetItems}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );

  const mealSlotMoreMenuButtonActionSheet = (
    <Actionsheet isOpen={isOpenMealSlotMenu} onClose={onCloseMealSlotMenu}>
      <Actionsheet.Content backgroundColor="white">
        <Actionsheet.Item
          onPress={(): void => {
            onCloseMealSlotMenu();
            onOpenCopyMealsTo();
          }}
          testID={"mealSlotMenu-actionsheet-copy-meals-button"}
          backgroundColor="white"
          _pressed={{ backgroundColor: theme.colors.primary["600"] }}
        >
          {t("planner.copy_meals_to_modal.copy_to")}
        </Actionsheet.Item>
      </Actionsheet.Content>
    </Actionsheet>
  );

  const addMoreComponent = (
    <Actionsheet isOpen={isOpenMealSlotAddMore} onClose={onCloseMealSlotAddMore}>
      <Actionsheet.Content backgroundColor="white">
        <Actionsheet.Item
          onPress={(): void => {
            onCloseMealSlotAddMore();
            onOpenQuickAdd();
          }}
          testID={"addMoreActionSheet-quickAdd-button"}
          backgroundColor="white"
          _pressed={{ backgroundColor: theme.colors.primary["600"] }}
        >
          {t("planner.quick_options.quick_add_title")}
        </Actionsheet.Item>
        <Actionsheet.Item
          onPress={() => {
            onCloseMealSlotAddMore();
            navigation.push(Routes.AddProductStack, {
              screen: Routes.FoodSearchScreen,
              params: {
                onChoose: async (product, suggestedServing, quantity) => {
                  if (!nutritionPlanForCurrentDay) {
                    throw new Error("nutritionPlanForCurrentDay is not set");
                  }

                  const mealSlotSpecification = findMealSlotSpecification(mealSlotForActionSheetItems);

                  if (!mealSlotSpecification) {
                    throw new Error("Could not find meal slot");
                  }

                  // NOTE: The below function is used in two places and is difficult to define before its use
                  // eslint-disable-next-line no-use-before-define
                  await addSingleFoodMealToPlannerWrapper(product, suggestedServing, quantity, mealSlotSpecification);

                  navigation.pop(1);
                },
              },
            });
          }}
          backgroundColor="white"
          _pressed={{ backgroundColor: theme.colors.primary["600"] }}
        >
          {t("planner.quick_options.add_product_title")}
        </Actionsheet.Item>
        <Actionsheet.Item
          onPress={() => {
            onCloseMealSlotAddMore();
            onPressPlanNewMeal(mealSlotForActionSheetItems);
          }}
          testID={"addMoreActionSheet-recipe-button"}
          backgroundColor="white"
          _pressed={{ backgroundColor: theme.colors.primary["600"] }}
        >
          {t("planner.quick_options.add_recipe_title")}
        </Actionsheet.Item>
      </Actionsheet.Content>
    </Actionsheet>
  );

  const shouldShowPreviewedBrandingHeader = Boolean(getOrganisationForBrandingPreview);

  const organisationForPreview = shouldShowPreviewedBrandingHeader
    ? getOrganisationForBrandingPreview()
    : { id: 1, name: "PLACEHOLDER_ORG", product_stripe_id: "prod_1234" };

  const showCustomBrandingHeader = shouldShowPreviewedBrandingHeader || (!isDesktop && !viewAsUser);

  const calendarStrip = React.useCallback(
    () => (
      <CalendarStripComponent
        currentDayInPlanner={currentDayInPlanner}
        leaveRoomForCustomBrandingHeader={showCustomBrandingHeader}
      />
    ),
    [showCustomBrandingHeader, currentDayInPlanner]
  );

  const noteComponent = (
    <View style={{ flex: 1, flexDirection: "row-reverse", marginHorizontal: Scale(10) }}>
      {currentCalendarDay && currentCalendarDay.note ? (
        <Badge colorScheme="primary" zIndex={1} variant="solid" alignSelf="flex-end" ml="2">
          <Text>{"1"}</Text>
        </Badge>
      ) : null}

      {user && isUserDiy(user) ? null : (
        <SimpleLineIcons
          name="note"
          size={24}
          color={theme.colors.primary["600"]}
          onPress={onOpenNoteDialog}
          testID="add-note-button"
          style={{ marginLeft: Scale(8) }}
        />
      )}
    </View>
  );

  const onPressGenerateCurrentDay = async (): Promise<void> => {
    if (!user) {
      throw new Error("user is not set");
    }

    await autoPlanDay({
      autoPlannerRequest: {
        date: formatMomentAsBackendFormatDateString(currentDayInPlanner),
        user: user.id,
      },
    });
  };

  // NOTE: This is not currently used because the auto-plan algorithm was felt to be repetitive by users
  const generateMealsComponent = (
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", marginHorizontal: Scale(10) }}>
      <Button
        marginBottom={4}
        style={{
          borderRadius: 10,
          paddingVertical: 12,
          paddingHorizontal: 25,
          alignSelf: "center",
          marginTop: 20,
          flexDirection: "row",
          alignItems: "center",
        }}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={async () => {
          // If there are any CalendarItems then give user a confirm dialog (use vanilla JS for the dialog)
          const areThereCalendarItemsForCurrentDay =
            currentCalendarDay && currentCalendarDay.calendar_items && currentCalendarDay.calendar_items.length > 0;

          if (areThereCalendarItemsForCurrentDay) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(t("planner.generate_meals_confirm_text"))) {
              await onPressGenerateCurrentDay();
            }
          } else {
            await onPressGenerateCurrentDay();
          }
        }}
        // NOTE: This is the old icon, but users found it confusing
        // leftIcon={<Icon as={Ionicons} name="color-wand" size="md" />}
        isLoading={isLoadingAutoPlanDay}
        disabled={!user || !currentDayInPlanner}
        testID="inside-planner-view-generate-meals-button"
      >
        <Text style={{ color: "white", fontSize: 16, fontWeight: "bold" }}>
          {t("planner.auto_generate_meals_button_text")}
        </Text>
      </Button>
    </View>
  );

  const onSubmitNote = async (values: NoteFormSchema): Promise<void> => {
    onCloseNoteDialog();

    if (!currentCalendarDay) {
      throw new Error("currentCalendarDay is not set");
    }

    if (!currentCalendarDay.id) {
      throw new Error("currentCalendarDay.id is not set");
    }

    await partialUpdateCalendarDayBackendCall({
      id: currentCalendarDay.id,
      patchedCalendarDayRequest: {
        note: values.note,
      },
    });
  };

  const noteDialog = (): JSX.Element => (
    <Modal isOpen={isOpenNoteDialog} onClose={onCloseNoteDialog} size="xl">
      <Modal.Content bgColor={"white"}>
        <Modal.CloseButton testID="note-modal-close-button" />
        <Modal.Header fontSize="4xl" fontWeight="bold" bgColor={"white"}>
          {t("planner.note_dialog.title")}
        </Modal.Header>
        <Modal.Body bgColor={"white"}>
          <Text
            style={{
              fontStyle: "italic",
            }}
          >
            {organisation?.custom_notes_message
              ? organisation?.custom_notes_message
              : t("planner.note_dialog.information_label")}
          </Text>

          <Formik
            initialValues={noteSchema.cast({
              note: currentCalendarDay?.note || "",
            })}
            validationSchema={noteSchema}
            onSubmit={onSubmitNote}
          >
            {({ handleSubmit, handleChange, values, dirty, isValid, isSubmitting }) => (
              <>
                <TextArea
                  autoCompleteType="off"
                  placeholder={t("planner.note_dialog.textarea_placeholder")}
                  value={values.note}
                  onChangeText={handleChange("note")}
                  mt="3"
                  testID="note-textarea"
                />

                <Button
                  isDisabled={!dirty || !isValid}
                  isLoading={isSubmitting || isLoadingPartialUpdateCalendarDay}
                  onPress={() => handleSubmit()}
                  mt="3"
                  testID="note-submit-button"
                >
                  {t("planner.note_dialog.save_button")}
                </Button>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );

  const hasMealsPlanned = (): boolean =>
    Boolean(currentCalendarDay && currentCalendarDay.calendar_items && currentCalendarDay.calendar_items.length > 0);

  const bodyIfCalendarDayExists = (
    <>
      {noteComponent}
      {diaryComponent}
    </>
  );

  const onPressCreateEmptyDay = async (): Promise<void> => {
    if (!user || !calendarDayListResponse || !weeklyNutritionPlan) {
      throw new Error("user, calendarDayListResponse or weeklyNutritionPlan is not set");
    }

    await createCalendarDays(
      calendarDayListResponse,
      // No specific generic provided, which should be fine
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      dispatch,
      plannerArgs,
      createNewCalendarDay,
      user,
      weeklyNutritionPlan,
      isLoadingCreateNewCalendarDay,
      isLoadingCalendarDayList,
      calendarDaysInStore
    );
  };

  const lastDateThereAreMealsPlannedWithThisNutritionDayPlan = findLastDateThereAreMealsPlannedWithThisNutritionDayPlan(
    nutritionPlanForCurrentDay,
    weeklyNutritionPlan,
    currentDayInPlanner,
    calendarDaysInStore
  );

  const onPressCopyFromLastRelevantDate = async (): Promise<void> => {
    if (
      !lastDateThereAreMealsPlannedWithThisNutritionDayPlan ||
      !lastDateThereAreMealsPlannedWithThisNutritionDayPlan.id
    ) {
      throw new Error("lastDateThereAreMealsPlannedWithThisNutritionDayPlan is not set");
    }

    if (!user) {
      throw new Error("user is not set");
    }

    await copyFromDate({
      copyFromDateRequest: {
        user: user.id,
        from_calendar_day_id: lastDateThereAreMealsPlannedWithThisNutritionDayPlan.id,
        to_date: formatMomentAsBackendFormatDateString(currentDayInPlanner),
      },
    });
  };

  const bodyIfNoCalendarDay = (
    <>
      <Center mb="3">
        {/* NOTE: This text no longer seems to be good UX */}
        {/* <Text
          style={{
            fontSize: 24,
            textAlign: "center",
            color: theme.colors.primary["600"],
            marginTop: Scale(20),
          }}
        >
          {t("planner.new_day.this_day_has_no_meals_planned_label")}
        </Text> */}

        {/* NOTE: This is currently disabled because the auto-planning algorithm produces repetitive plans */}
        {/* <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={onPressGenerateCurrentDay}
          isLoading={isLoadingAutoPlanDay}
          testID={"generate-day-button"}
          // NOTE: This is the old icon that people were confused by
          // leftIcon={<Ionicons name="color-wand" color={"white"} size={28} />}
          mt="8"
          mb="3"
        >
          <Text style={{ fontSize: 20, color: "white" }}>
            {t("planner.new_day.generate_meals_for_day_button_text")}
          </Text>
        </Button> */}

        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={onPressCreateEmptyDay}
          leftIcon={<MaterialCommunityIcons name="pencil" color={"white"} size={28} />}
          testID="create-empty-day-button"
          isDisabled={!user || !calendarDayListResponse || !weeklyNutritionPlan}
          mb="4"
        >
          <Text style={{ fontSize: 20, color: "white" }}>{t("planner.new_day.generate_empty_day_button_text")}</Text>
        </Button>

        {lastDateThereAreMealsPlannedWithThisNutritionDayPlan ? (
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={onPressCopyFromLastRelevantDate}
            isDisabled={!weeklyNutritionPlan || !nutritionPlanForCurrentDay || !user}
            isLoading={isLoadingCopyFromDate}
            testID="copy-day-button"
            leftIcon={<MaterialIcons name="content-copy" color={"white"} size={28} />}
            mb="3"
          >
            <Text style={{ fontSize: 20, color: "white" }}>
              {t("planner.new_day.copy_from_day_button_text", {
                day: getLocalizedDay(lastDateThereAreMealsPlannedWithThisNutritionDayPlan.date, userLocaleFromStore),
              })}
            </Text>
          </Button>
        ) : null}
      </Center>
    </>
  );

  // Add this useEffect hook to check the subscription when the screen loads
  useEffect(() => {
    const verifySubscription = async (): Promise<void> => {
      if (user && user.email) {
        if (!isUserInPaymentRequiredOrganisation(user)) {
          // Only do this check for payment required organisations
          return;
        }

        try {
          const result = await checkSubscription({
            subscriptionVerificationRequest: { email: user.email },
          }).unwrap();

          if (result.has_active_subscription === false) {
            // NOTE: If we don't do this then the screen will not re-render
            navigation.navigate(Routes.BillingIssueScreen);
          }
        } catch (error) {
          // Log the error but don't block the user
          logger.warn("Error checking subscription:", error);
        }
      }
    };

    verifySubscription().catch((err) => console.error(err));
  }, [user, checkSubscription, t]);

  return (
    <SafeAreaView style={commonStyles.container}>
      {showCustomBrandingHeader ? (
        <CustomBrandingMobileScreenHeader
          organisationForPreview={shouldShowPreviewedBrandingHeader ? organisationForPreview : undefined}
          previewingOnDesktopScreen={isDesktop}
        />
      ) : null}
      {viewAsUserHeaderComponent}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ width: "100%", paddingHorizontal: isDesktop ? "25%" : 0 }}
        nativeID="diaryViewScreenView"
      >
        {calendarStrip()}

        {/* This shows "Next Meal Plan in X Days" */}
        {/* NOTE: This is not shown at the moment because the cronjob that does this
        is disabled and thus it confuses users */}
        {/* {organisation && doesOrganisationHaveManagedPlanning(organisation) ? (
          <View>
            {isDateInCurrentWeek(currentDayInPlanner) ? (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: Scale(10),
                }}
              >
                <Icon as={Ionicons} name="time-outline" />
                <Text style={{ fontSize: 12, color: theme.colors.primary["600"] }}>
                  {getNumberOfDaysUntilNextMonday(currentDayInPlanner) === 0
                    ? t("planner.new_plan_today")
                    : t("planner.days_until_next_meal_plan", {
                        numberOfDays: getNumberOfDaysUntilNextMonday(currentDayInPlanner),
                      })}
                </Text>
              </View>
            ) : null}
          </View>
        ) : null} */}
        <CommonHeaderDivider />

        {!weeklyNutritionPlan ? noWeeklyNutritionPlanMessage : null}
        {nutritionOverviewForTheDay}

        <View style={{ marginTop: Scale(40) }}>
          {!currentCalendarDay ? bodyIfNoCalendarDay : bodyIfCalendarDayExists}
        </View>
      </ScrollView>
      {!weeklyNutritionPlan ? null : floatingButton}
      {quickAddModal}
      {addMoreComponent}
      {mealSlotMoreMenuButtonActionSheet}
      {copyMealsToModalComponent}
      {noteDialog()}
    </SafeAreaView>
  );
};

export default DiaryViewScreen;
