import { Ionicons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import * as DocumentPicker from "expo-document-picker";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import { Formik, FormikProps } from "formik";
import _ from "lodash";
import {
  AlertDialog,
  Button,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  NativeBaseProvider,
  ScrollView,
  Text,
  useDisclose,
  View,
} from "native-base";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";
import ColorPicker, { Panel3, Preview, returnedResults, Swatches } from "reanimated-color-picker";
import * as Yup from "yup";

import { commonStyles, isDesktopScreen, Routes, Scale, VerticalScale } from "../constants";
import { createThemeFromColour } from "../constants/theme";
import { IS_MOBILE_WEB, isMobilePlatform } from "../helpers/generalHelpers";
import { getOrganisation } from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { UsersOrganisationCreateApiArg, UsersOrganisationPartialUpdateApiArg } from "../services/backendTypes";
import { userSelector } from "../slices/userSlice";
import DiaryViewScreen from "./DiaryViewScreen";

const organisationFormSchema = Yup.object().shape({
  organisationName: Yup.string().required().default(""),
  // primaryColor should be a hex code and validated as such
  // TODO: Use https://www.npmjs.com/package/validate-color
  primaryColor: Yup.string().required().default("#00bcd4"),
  logoAsBase64: Yup.string().default(""),
});

const { useUsersOrganisationCreateMutation, useUsersOrganisationPartialUpdateMutation } = backendApi;

export type OrganisationFormSchema = Yup.InferType<typeof organisationFormSchema>;

type Props = NativeStackScreenProps<RootStackParamList, Routes.CoachModeAccountScreen>;
const CoachModeAccountScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();

  const { isOpen: isOpenPreviewDialog, onOpen: onOpenPreviewDialog, onClose: onClosePreviewDialog } = useDisclose();

  const [createOrganisation] = useUsersOrganisationCreateMutation();
  const [partialUpdateOrganisation] = useUsersOrganisationPartialUpdateMutation();

  const [showColourPickerModal, setShowColourPickerModal] = React.useState(false);

  const user = useSelector(userSelector);
  const organisation = user ? getOrganisation(user) : undefined;
  const formRef = React.useRef<FormikProps<OrganisationFormSchema>>(null);

  const organisationFormInitialValues = organisationFormSchema.cast({
    organisationName: organisation?.name ?? "",
    primaryColor: organisation?.primary_colour ?? "",
  });

  const commonContainerStyle = {
    backgroundColor: "white",
    flex: 1,
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
    backgroundColor: "white",
  };

  const onSubmitOrganisation = async (values: OrganisationFormSchema): Promise<void> => {
    // TODO: Update if exists
    if (!organisation) {
      const payload: UsersOrganisationCreateApiArg = {
        updateCreateOrganisationRequest: {
          name: values.organisationName,
          primary_colour: values.primaryColor,
          // NOTE: The type is incorrect here
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          logo: values.logoAsBase64,
        },
      };

      try {
        await createOrganisation(payload).unwrap();
      } catch (error) {
        Sentry.captureException(error);
        alert(t("general.generic_error_message"));
        return;
      }
    } else {
      const payload: UsersOrganisationPartialUpdateApiArg = {
        id: organisation.id,
        patchedUpdateCreateOrganisationRequest: {
          name: values.organisationName,
          primary_colour: values.primaryColor,
        },
      };

      if (values.logoAsBase64) {
        // NOTE: The type is incorrect here
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.patchedUpdateCreateOrganisationRequest.logo = values.logoAsBase64;
      }

      try {
        await partialUpdateOrganisation(payload).unwrap();
      } catch (error) {
        Sentry.captureException(error);
        alert(t("general.generic_error_message"));
        return;
      }
    }

    alert(t("general.updated_successfully"));
  };

  const deactivateClientDialogRef = useRef(null);
  const addPreviewDialog = (
    <AlertDialog
      leastDestructiveRef={deactivateClientDialogRef}
      isOpen={isOpenPreviewDialog}
      onClose={() => {
        onClosePreviewDialog();
      }}
      size={"xl"}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header backgroundColor={"white"}>{t("coach_mode_account.preview")}</AlertDialog.Header>
        <ScrollView showsVerticalScrollIndicator={false}>
          <NativeBaseProvider theme={createThemeFromColour(formRef.current?.values.primaryColor)}>
            <DiaryViewScreen
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              navigation={navigation}
              route={{
                // NOTE: The compiler thinks there is a problem because we are
                // instantiating this screen instead of navigating to it
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                params: {
                  getOrganisationForBrandingPreview: () => ({
                    id: organisation?.id || 1,
                    name: formRef.current?.values.organisationName || "",
                    primary_colour: formRef.current?.values.primaryColor,
                    logo: formRef.current?.values.logoAsBase64 || organisation?.logo,
                    product_stripe_id: organisation?.product_stripe_id || null,
                  }),
                },
              }}
            />
          </NativeBaseProvider>
        </ScrollView>
      </AlertDialog.Content>
    </AlertDialog>
  );
  const flexWidth = isMobilePlatform() || IS_MOBILE_WEB ? "100%" : "50%";

  const headerComponent = (
    <Flex
      flexDirection={"column"}
      justifyContent="space-between"
      alignItems={"center"}
      style={commonStyles.paddingContainer}
      nativeID={"customBrandingHeaderComponent"}
    >
      <Text bold fontSize={"6xl"}>
        {t("coach_mode_account.branding_header")}
      </Text>
    </Flex>
  );

  const onPressPreview = (): void => onOpenPreviewDialog();
  return (
    <View
      style={[
        isMobilePlatform() ? mobileContainerStyle : commonContainerStyle,
        { paddingTop: isMobilePlatform() ? VerticalScale(50) : 0 },
      ]}
    >
      {headerComponent}

      <Flex flex={1} pt="4" bgColor="white" width={flexWidth} marginX="auto">
        <Formik
          initialValues={organisationFormInitialValues}
          validationSchema={organisationFormSchema}
          onSubmit={onSubmitOrganisation}
          innerRef={formRef}
        >
          {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, dirty, isValid }) => {
            const selectLogoFile = async (): Promise<void> => {
              const documentResult = await DocumentPicker.getDocumentAsync({
                copyToCacheDirectory: true,
                type: "image/*",
              });

              // Check file size is less than 1MB and show alert if not
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (documentResult.size > 1000000) {
                alert(t("coach_mode_account.logo_file_size_error"));
                return;
              }

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              handleChange("logoAsBase64")(documentResult.uri);
            };

            return (
              <>
                <AlertDialog.Body backgroundColor={"white"}>
                  <View>
                    <Flex flexDirection={"row-reverse"}>
                      {/* TODO: The preview button should be in a better place */}
                      <TouchableOpacity style={{}} onPress={onPressPreview}>
                        <Icon as={Ionicons} m="2" ml="3" size="6" color={"black"} name="eye" />
                      </TouchableOpacity>
                    </Flex>

                    {/* TODO: Change this to be "Brand name" */}
                    <FormControl isRequired isInvalid={!_.isEmpty(errors.organisationName)}>
                      <FormControl.Label>{t("coach_mode_account.business_name")}</FormControl.Label>
                      <Input
                        onBlur={handleBlur("organisationName")}
                        placeholder={t("coach_mode_account.business_name")}
                        onChangeText={handleChange("organisationName")}
                        value={String(values.organisationName)}
                        testID={"organisationNameInput"}
                      />
                      <FormControl.ErrorMessage>{errors.organisationName}</FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!_.isEmpty(errors.primaryColor)} mt={4}>
                      <FormControl.Label>{t("coach_mode_account.primary_color")}</FormControl.Label>

                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Button
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onPress={() => setShowColourPickerModal(true)}
                          mt={2}
                          mr={2}
                          mb={2}
                          rightIcon={<Icon as={Ionicons} name="color-palette" />}
                          nativeID="openColourPickerButton"
                          testID={"openColourPickerButton"}
                          style={{ width: "8%" }}
                        ></Button>

                        <Input
                          borderWidth={1}
                          height={35}
                          borderRadius={4}
                          backgroundColor={values.primaryColor}
                          width={"92%"}
                          onBlur={handleBlur("primaryColor")}
                          placeholder={t("coach_mode_account.primary_color")}
                          onChangeText={handleChange("primaryColor")}
                          value={String(values.primaryColor)}
                          testID={"primaryColorInput"}
                        />
                      </View>
                      <Modal isOpen={showColourPickerModal} onClose={() => setShowColourPickerModal(false)}>
                        <Modal.Content maxWidth="400px">
                          <Modal.CloseButton />
                          <Modal.Body></Modal.Body>
                          <Modal.Footer>
                            <ColorPicker
                              value={values.primaryColor}
                              onComplete={(colors: returnedResults) => {
                                handleChange("primaryColor")(colors.hex);
                              }}
                              style={{ width: "100%" }}
                            >
                              <Preview hideInitialColor />
                              <Panel3 />
                              <Swatches />
                            </ColorPicker>
                          </Modal.Footer>
                        </Modal.Content>
                      </Modal>

                      <FormControl.ErrorMessage>{errors.primaryColor}</FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!_.isEmpty(errors.logoAsBase64)}>
                      <FormControl.Label>{t("coach_mode_account.logo_field_label")}</FormControl.Label>
                      {organisation?.logo || values.logoAsBase64 ? (
                        <Image
                          source={{ uri: values.logoAsBase64 || organisation?.logo }}
                          style={{ width: Scale(160), height: VerticalScale(60) }}
                          mt={4}
                          alignSelf={"center"}
                          resizeMode="contain"
                          alt="Coach logo"
                        />
                      ) : null}

                      <Button
                        mt={4}
                        leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" />}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onPress={selectLogoFile}
                        color={"white"}
                        testID={"uploadLogo-button"}
                      >
                        {t("coach_mode_account.upload_your_logo")}
                      </Button>
                      <FormControl.ErrorMessage>{errors.logo}</FormControl.ErrorMessage>
                    </FormControl>
                    <Button
                      mt={4}
                      onPress={() => handleSubmit()}
                      isLoading={isSubmitting}
                      isDisabled={!dirty || !isValid}
                      testID={"organisationForm-submitButton"}
                    >
                      {t("coach_mode_account.save")}
                    </Button>
                  </View>
                </AlertDialog.Body>
              </>
            );
          }}
        </Formik>
        {addPreviewDialog}
      </Flex>
    </View>
  );
};
export default CoachModeAccountScreen;
